import { useStyles } from '@team-griffin/stylr';
import { modifier, percentage } from '@ux/style-utils';
import { getTitleColor, getBodyColor } from 'presentation/utils/colorway';
import { gu2px } from '@ux/theme-utils';
import { Alignment } from 'domain/constants/alignment';
import { InnerProps } from './Info';
import { CSSProperties } from 'react';

export interface Styles {
  root: CSSProperties,
  title: CSSProperties,
  subtitle: CSSProperties,
  ctas: CSSProperties,
  cta: CSSProperties,
}
export default ({
  theme,
  layout,
  colorway,
  isMinSm,
}: InnerProps) => {
  const {
    spacing,
  } = theme;

  const {
    alignment,
  } = layout;

  return useStyles({
    default: {
      root: {
        textAlign: 'left',
        display: 'block',
        width: percentage(100),
      },
      title: {
        color: getTitleColor(theme, colorway),
      },
      subtitle: {
        color: getBodyColor(theme, colorway),
      },
      ctas: {
        overflow: 'hidden',
        marginTop: gu2px(2, spacing),
      },
      cta: {
        marginTop: gu2px(1, spacing),
      },
    },

    isMinSm: {
      ctas: {
        display: 'flex',
      },
      cta: {
        marginRight: gu2px(1, spacing),
      },
    },

    [modifier('alignment', Alignment.CENTER)]: {
      root: {
        textAlign: 'center',
      },
      ctas: {
        justifyContent: 'center',
      },
    },

  }, [
    { isMinSm },
    { alignment },
  ]);
};
