import React from 'react';
import {
  withProps,
  defaultProps,
} from '@team-griffin/rehook';
import pureEnhance from '@ux/pure-enhance';
import { Container } from '@ux/grid';
import PaddingBox from '@ux/padding-box';
import { Heading02, SubHeading01 } from '@ux/typography';
import Shortcode, { ShortcodeProps } from 'components/Shortcode';
import Ribbon from 'components/ribbons/Ribbon';
import CallToAction from 'components/common/CallToAction';
import { withBreakpointsHook, BreakpointProps } from '@ux/responsive';
import { isNotNilOrEmpty } from 'crosscutting/ramda';
import visibilitySet from 'presentation/hocs/visibilitySet';
import errorBoundary from 'presentation/hocs/errorBoundary';
import { getAgainst } from 'presentation/utils/colorway';
import stylesheet, { Styles } from './Info.stylesheet';
import * as r from 'ramda';
import { RibbonProps } from 'domain/constants/ribbons';
import { CTA } from 'core/ribbons/cta';

interface OuterProps extends RibbonProps {
  title: ShortcodeProps,
  subtitle: ShortcodeProps,
  ctas: CTA[],
}
interface DefaultProps extends OuterProps {
  hBaseline: number,
}
export interface InnerProps extends DefaultProps, BreakpointProps {
  styles: Styles,
  hasTitle: boolean,
  hasSubtitle: boolean,
  hasCta: boolean,
}

declare const i: number;
declare const cta: CTA;

export const PureInfo = ({
  id,
  styles,
  theme,
  loading,
  name,
  title,
  subtitle,
  ctas,
  colorway,
  hasTitle,
  hasSubtitle,
  hasCta,
  hBaseline,
  isMinSm,
  layout,
}: InnerProps) => (
  <Ribbon
    id={id}
    theme={theme}
    colorway={colorway}
    loading={loading}
    layout={layout}
    name={name}
    type="Info"
  >
    <div style={styles.root}>
      <Container>
        <PaddingBox
          theme={theme}
        >
          <If condition={hasTitle}>
            <Heading02
              theme={theme}
              depth={hBaseline}
              style={styles.title}
            >
              <Shortcode
                theme={theme}
                against={colorway}
                code={title}
              />
            </Heading02>
          </If>
          <If condition={hasSubtitle}>
            <SubHeading01
              theme={theme}
              component="p"
              style={styles.subtitle}
            >
              <Shortcode
                theme={theme}
                against={colorway}
                code={subtitle}
              />
            </SubHeading01>
          </If>
          <If condition={hasCta}>
            <div style={styles.ctas}>
              <For
                each="cta"
                of={ctas}
                index="i"
              >
                <div
                  key={i}
                  style={styles.cta}
                >
                  <CallToAction
                    theme={theme}
                    against={getAgainst(colorway)}
                    ribbonId={id}
                    index={i}
                    block={r.not(isMinSm)}
                    {...cta}
                  />
                </div>
              </For>
            </div>
          </If>
        </PaddingBox>
      </Container>
    </div>
  </Ribbon>
);

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'Intro', {
    hocs: [
      errorBoundary,
      visibilitySet,
    ],
    hooks: [
      withBreakpointsHook,
      defaultProps({
        hBaseline: 1,
      }),
      withProps((ownerProps: DefaultProps) => ({
        hasTitle: isNotNilOrEmpty(ownerProps.title),
        hasSubtitle: isNotNilOrEmpty(ownerProps.subtitle),
        hasCta: isNotNilOrEmpty(ownerProps.ctas),
      })),
      withProps(stylesheet),
    ],
  }
);

export default enhance(PureInfo);
