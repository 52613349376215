import React from 'react';
import {
  defaultProps,
} from '@team-griffin/rehook';
import Button, {
  Against,
  Status,
  Size,
} from '@ux/reactive-button';
import callToAction from 'presentation/hocs/callToAction';
import * as r from 'ramda';
import pureEnhance from '@ux/pure-enhance';
import { Theme } from '@ux/theme-utils';
import { CTA } from 'core/ribbons/cta';
import { Product } from '@ux/product';

interface OuterProps extends CTA {
  product?: Product,
  theme: Theme,
  against?: Against,
  block?: boolean,
  size?: Size,
  ribbonId: string,
  index: number,
  glyph?: string,
}
interface DefaultProps extends OuterProps {
  against: Against,
}
interface InnerProps extends DefaultProps {
  addingStatus: Status,
  handleClick: (props: any) => () => void,
}

const PureCallToAction = ({
  kind,
  against,
  label,
  addingStatus,
  handleClick,
  ...props
}: InnerProps) => {
  const status = r.cond([
    [ r.equals('REQUESTED'), r.always(Status.PENDING) ],
    [ r.equals('SUCCESS'), r.always(Status.SUCCESS) ],
    [ r.equals('FAILED'), r.always(Status.FAILURE) ],
    [ r.T, r.always(Status.DEFAULT) ],
  ])(addingStatus);

  const goto = r.pathOr(null, [ 'behavior', 'goto' ], props);

  const buttonProps = r.omit([
    'colorway',
    'actions',
    'buttonId',
    'ribbonId',
    'index',
    'behavior',
    'handlePlayVideoClick',
    'handleSetVisibilityClick',
    'handleConfigureClick',
    'handleApplyVoucherClick',
    'handleAddToBasketClick',
    'handleNavigateClick',
    'handleAnalytics',
    'ribbonList',
  ], props);

  return (
    <Button
      kind={kind}
      against={against}
      status={status}
      onClick={handleClick}
      to={goto}
      {...buttonProps}
    >
      {label}
    </Button>
  );
};

export const enhance = pureEnhance<InnerProps, OuterProps>(
  'CallToAction',
  {
    hocs: [
      callToAction,
    ],
    hooks: [
      defaultProps({
        against: Against.LIGHT,
      }),
    ],
  },
);

export default enhance(PureCallToAction);
