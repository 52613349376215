export enum Behavior {
  NAVIGATION = 'navigation',
  ADD_TO_BASKET = 'addToBasket',
  APPLY_VOUCHER = 'APPLY_VOUCHER',
  CONFIGURE = 'configure',
  SET_VISIBILITY = 'changeActiveVisibilitySet',
  PLAY_VIDEO = 'playVideo',
}

export default null;
