import {
  compose,
  defaultProps,
  withProps,
  withHandlers,
} from 'recompose';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as r from 'ramda';
import { addToBasket, applyVoucher } from 'application/signals/basket';
import { setVisibility } from 'application/signals/ribbons';
import {
  productClicked,
  promotionClicked,
  productAddToBasket,
} from 'application/signals/cta';
import { getAddingStatus } from 'domain/selectors/basket';
import { getExternalRoutes } from 'domain/selectors/common';
import { createRibbonName } from 'domain/transformers/ribbons';
import { getRibbon } from 'domain/selectors/ribbons';
import { Behavior } from 'domain/constants/callToAction';
import { push } from 'connected-react-router';
import { signals } from '@team-griffin/redux-modal-router';
import { isNotNilOrEmpty } from '@team-griffin/capra';
import { selectors } from '@ux/product';
import qs from 'qs';

const formatPricingTier = r.pipe(
  r.converge(
    r.unapply(r.identity),
    [
      r.prop('paymentInterval'),
      r.prop('term'),
    ],
  ),
  // if both interval and term are null then we don't want to return anything
  r.ifElse(
    r.all(r.isNil),
    r.always(void 0),
    r.pipe(
      r.map(r.defaultTo('')),
      r.join(':'),
    ),
  ),
);

const mapStateToProps = (state, props) => {
  const buttonId = `${props.ribbonId}-cta-${props.index}`;
  const externalRoutes = getExternalRoutes(state);
  const ribbon = getRibbon(props.ribbonId)(state);
  const ribbonType = r.prop('type', ribbon);
  const ribbonName = r.path([ 'props', 'name' ], ribbon);
  const product = r.path(['product' ], props);

  const ribbonList = createRibbonName(ribbonType, ribbonName);
  return {
    externalRoutes,
    addingStatus: getAddingStatus(buttonId)(state),
    buttonId,
    ribbonList,
    product,
  };
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
  addToBasket,
  applyVoucher,
  setVisibility,
  push,
  openReduxModal: signals.open,
  productClicked,
  promotionClicked,
  productAddToBasket,
}, dispatch);

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  return {
    ...stateProps,
    ...ownProps,
    actions: {
      ...dispatchProps,
      ...ownProps.actions,
    },
  };
};

export default compose(
  defaultProps({
    behavior: {},
  }),
  connect(
    mapStateToProps,
    mapDispatchToProps,
    mergeProps,
  ),
  withHandlers({
    handleAnalytics: (props) => () => {
      const ctaType = r.path([ 'behavior', 'type' ], props);
      const type = r.path([ 'behavior', 'analytics', 'type' ], props);
      const list = r.prop('ribbonList', props);
      const product = r.prop('product', props);

      if (type === 'PRODUCT') {
        const productId = r.path([ 'behavior', 'analytics', 'productId' ], props);
        props.actions.productClicked({
          productId,
          list,
        });
      } else if (type === 'PROMOTION') {
        const promotion = r.path([ 'behavior', 'analytics', 'promotion' ], props);
        props.actions.promotionClicked({ promotion });
      } else if (ctaType === 'addToBasket') {
        props.actions.productAddToBasket({
          list,
          product,
        });
      }
    },
  }),
  withHandlers({
    handleAddToBasketClick: (props) => () => {
      const {
        buttonId,
        product,
        actions: {
          addToBasket,
        },
        behavior: {
          productId,
          postAction,
          pricingTier,
        },
        handleAnalytics,
      } = props;
      const productData = {
        name: product ? product.name : null,
        price: product ? selectors.getPrice({product, includeTax: false}) : null,
        category: product ? product.categoryName : null,
      };
      addToBasket(
        buttonId,
        productId,
        postAction,
        formatPricingTier(pricingTier),
        1, //quantity
        productData,
      );

      handleAnalytics();
    },
    handleNavigateClick: ({ handleAnalytics }) => () => {
      handleAnalytics();
    },
    handleApplyVoucherClick: ({
      buttonId,
      actions: {
        applyVoucher,
      },
      behavior: {
        voucherCode,
        postAction,
      },
    }) => () => {
      applyVoucher(buttonId, voucherCode, postAction);
    },
    handleConfigureClick: ({
      actions: {
        push,
      },
      behavior: {
        productId,
        initialSku,
        pricingTier,
      },
    }) => () => {
      const query = qs.stringify({
        productId,
        initialSku,
        pricingTier: formatPricingTier(pricingTier),
      });
      push({
        pathname: '/configuration',
        search: `?${query}`,
      });
    },
    handleSetVisibilityClick: ({
      actions: {
        setVisibility,
      },
      behavior,
    }) => () => {
      setVisibility(behavior.visibilitySet);
    },
    handlePlayVideoClick: ({
      actions: {
        openReduxModal,
      },
      behavior: {
        videoId,
        provider,
      },
    }) => () => {
      openReduxModal({
        route: 'PLAY_VIDEO',
        params: {
          videoId,
          provider,
        },
      });
    },
  }),
  withProps((ownerProps) => ({
    handleClick: r.cond([
      [
        r.propEq('type', Behavior.ADD_TO_BASKET),
        r.always(ownerProps.handleAddToBasketClick),
      ],
      [
        r.propEq('type', Behavior.APPLY_VOUCHER),
        r.always(ownerProps.handleApplyVoucherClick),
      ],
      [
        r.propEq('type', Behavior.CONFIGURE),
        r.always(ownerProps.handleConfigureClick),
      ],
      [
        r.propEq('type', Behavior.SET_VISIBILITY),
        r.always(ownerProps.handleSetVisibilityClick),
      ],
      [
        r.propEq('type', Behavior.PLAY_VIDEO),
        r.always(ownerProps.handlePlayVideoClick),
      ],
      [
        r.propEq('type', Behavior.NAVIGATION),
        r.always(ownerProps.handleNavigateClick),
      ],
      [
        r.T,
        // noop
        r.always(r.always(null)),
      ],
    ])(ownerProps.behavior),
    // add an analytics prefixed `id` to the cta if required
    id: r.ifElse(
      r.pathSatisfies(isNotNilOrEmpty, [ 'analytics', 'linkAttribution' ]),
      ({ analytics }) => `usf-cta-${analytics.linkAttribution}`,
      r.always(ownerProps.id),
    )(ownerProps.behavior),
  })),
);
